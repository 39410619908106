import ProjectsList from '../Components/ProjectsList';
import TitleSticky from '../Components/TitleSticky';

function Homepage() {
    return (
        <div className="t-homepage">
            <TitleSticky title={'<strong>Art</strong> Gallery'} />
            <ProjectsList />
        </div>
    );
}

export default Homepage;
