import React from 'react';
import { formatDate, formatHour } from '../Helpers/FormatDate';
import ReactMarkdown from 'react-markdown'

function Event({ title, description, date_start, date_end, picture, link }) {
    return (
        <div className="c-event">
            {
                picture &&
                <div className="c-event__visuel" style={{ backgroundImage: `url(${picture.data.attributes.formats.medium ? picture.data.attributes.formats.medium.url : picture.data.attributes.url})` }}>
                </div>
            }
            <div className="c-event__description">
                {
                    title &&
                    <h2 className="c-event__description-title">{title}</h2>
                }
                <div className="c-event__description-date">
                    {
                        formatDate(date_start) === formatDate(date_end) ?
                            <>
                                <p className="c-event__description-date-item">Le {formatDate(date_start)}&nbsp;de {`${formatHour(date_start)} à ${formatHour(date_end)}`}</p>
                            </>
                            :
                            <>
                                {
                                    date_start &&
                                    <p className="c-event__description-date-item">Du {formatDate(date_start)}&nbsp;à {formatHour(date_start)}</p>
                                }
                                {
                                    date_end &&
                                    <p className="c-event__description-date-item">au {formatDate(date_end)} à {formatHour(date_end)}</p>
                                }
                            </>
                    }
                </div>
                {
                    description &&
                    <div className="c-event__description-text"><ReactMarkdown>{description}</ReactMarkdown></div>
                }
                {
                    link &&
                    <a href={link} rel="noreferrer" target="_blank" className="custom-btn btn-border button-event"><span>+ d'infos</span></a>
                }
            </div>
        </div>
    )
}

export default Event;
