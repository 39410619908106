import { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "./Loader";
import { NavLink } from "react-router-dom";

function ContactForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [sendStatus, setSendStatus] = useState("");
  const onSubmit = data => {
    if (data !== null) {
      setSendStatus("pending");
      fetch("https://clapaint.fly.dev/api/messages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }).then(res => {
        if (res.status === 200) {
          setSendStatus("sent");
        } else {
          setSendStatus("error");
        }
      });
    }
  };

  return (
    <>
      {sendStatus === "sent" ? (
        <div className="c-contactForm__success">
          <h2 className="c-contactForm__success-title">
            Message envoyé avec succès
          </h2>
          <NavLink to="/" className="custom-btn btn-border button-event">
            <span>Retour</span>
          </NavLink>
        </div>
      ) : (
        <>
          <h2 className="t-contact__container-title">Formulaire de contact</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="c-contactForm">
            <div className="c-contactForm__input">
              <label
                className={`c-contactForm__input__container ${
                  firstName !== "" ? "filled" : ""
                }`}
              >
                <input
                  type="text"
                  {...register("firstname", { required: true })}
                  onChange={e => {
                    setFirstName(e.target.value);
                    clearErrors("firstname");
                  }}
                />
                <span className="c-contactForm__input-label">Prénom</span>
                {errors.firstname && (
                  <span className="c-contactForm__input-error">
                    Veuillez remplir ce champs
                  </span>
                )}
              </label>
            </div>
            <div className="c-contactForm__input">
              <label
                className={`c-contactForm__input__container ${
                  lastName !== "" ? "filled" : ""
                }`}
              >
                <input
                  type="text"
                  {...register("lastname", { required: true })}
                  onChange={e => {
                    setLastName(e.target.value);
                    clearErrors("lastname");
                  }}
                />
                <span className="c-contactForm__input-label">Nom</span>
                {errors.lastname && (
                  <span className="c-contactForm__input-error">
                    Veuillez remplir ce champs
                  </span>
                )}
              </label>
            </div>
            <div className="c-contactForm__input">
              <label
                className={`c-contactForm__input__container ${
                  email !== "" ? "filled" : ""
                }`}
              >
                <input
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  onChange={e => {
                    setEmail(e.target.value);
                    clearErrors("email");
                  }}
                />
                <span className="c-contactForm__input-label">Email</span>
                {errors.email && (
                  <span className="c-contactForm__input-error">
                    Email invalide
                  </span>
                )}
              </label>
            </div>
            <div className="c-contactForm__input full textarea">
              <label
                className={`c-contactForm__input__container ${
                  message !== "" ? "filled" : ""
                }`}
              >
                <textarea
                  {...register("message", { required: true })}
                  onChange={e => {
                    setMessage(e.target.value);
                    clearErrors("message");
                  }}
                />
                <span className="c-contactForm__input-label">Message</span>
                {errors.message && (
                  <span className="c-contactForm__input-error">
                    message invalide
                  </span>
                )}
              </label>
            </div>
            <div className="c-contactForm__input full">
              {sendStatus === "pending" ? (
                <Loader />
              ) : sendStatus === "error" ? (
                <p className="c-contactForm__input-error">
                  Une erreur s'est produite veuillez recommencer
                </p>
              ) : (
                <button
                  type="submit"
                  className="custom-btn btn-border button-event"
                >
                  <span>Envoyer</span>
                </button>
              )}
            </div>
          </form>
        </>
      )}
    </>
  );
}

export default ContactForm;
