import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Header() {
    const [burgerOpen, setBurgerOpen] = useState(false)
    return (
        <div className="c-header">
            <nav className={`c-header__nav ${burgerOpen ? 'open' : ''}`}>
                <div className="c-header__burger" onClick={() => setBurgerOpen(!burgerOpen)}>
                    <span></span>
                </div>
                <ul className="c-header__menu">
                    <li onClick={() => setBurgerOpen(false)} className="c-header__menu-item">
                        <NavLink to="/" >Accueil</NavLink>
                    </li>
                    <li onClick={() => setBurgerOpen(false)} className="c-header__menu-item">
                        <NavLink to="/evenements" >Évènements</NavLink>
                    </li>
                    <li onClick={() => setBurgerOpen(false)} className="c-header__menu-item">
                        <NavLink to="/contact" >Contact</NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Header;
