import React, { useState } from 'react';

function Project({ picture, title, width, height, is_available, imgLoaded }) {

    const [loaded, setLoaded] = useState(false)
    return (
        <>
            {
                picture.data.attributes.url &&
                <div className={`c-project ${loaded ? '' : 'hide'}`}>
                    <div className="c-project-infos">
                        {
                            title &&
                            <h2 className="c-project-infos-title">{title}</h2>
                        }
                        {
                            width && height &&
                            <p className="c-project-infos-text">{width} x {height}</p>
                        }
                    </div>
                    <img onLoad={() => setLoaded(true)} src={picture.data.attributes.formats.medium ? picture.data.attributes.formats.medium.url : picture.data.attributes.url} alt="" />
                    <div className={`c-project-avaibility ${is_available ? 'available' : ''}`}>
                        <p>{is_available ? "Disponible" : "Non disponible"}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default Project;
