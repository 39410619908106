import React, { useState, useEffect } from "react";
import Masonry from "react-masonry-component";
// COMPONENTS
import Project from "./Project";
import PopinProject from "./PopinProject";
import Loader from "./Loader";
// LIB
import qs from "qs";

const getWorks = async pageNumber => {
  const query = qs.stringify(
    {
      sort: ["is_highlight:desc", "created_date:desc"],
      populate: ["picture"],
      pagination: {
        page: pageNumber,
        pageSize: 30,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return await fetch(`https://clapaint.fly.dev/api/works?${query}`).then(
    res => {
      return res.json();
    }
  );
};

function ProjectsList() {
  const [openPopin, setOpenPopin] = useState(false);
  const [infosPopin, setInfosPopin] = useState({});
  const [worksLoded, setWorksLoaded] = useState(false);
  const [gridLoaded, setGridLoaded] = useState(false);
  const [works, setWorks] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const handlePreview = project => {
    setOpenPopin(true);
    setInfosPopin(project);
  };

  const loadMore = async () => {
    const newDatas = await getWorks(currentPage + 1);
    setWorks([
      ...works,
      ...newDatas.data.filter(w => works.map(w => w.id).indexOf(w.id) === -1),
    ]);
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    (async () => {
      const allWorks = await getWorks(currentPage);
      setWorks(allWorks.data);
      setTotalPages(allWorks.meta.pagination.pageCount);
      setWorksLoaded(true);
    })();
  }, []);

  return (
    <>
      <div className="c-projectsList">
        {worksLoded ? (
          <>
            {!gridLoaded && <Loader />}
            <Masonry
              onLayoutComplete={() => setGridLoaded(true)}
              updateOnEachImageLoad={true}
              className={`c-projectsList__gallery ${gridLoaded ? "" : "hide"}`}
              elementType={"ul"}
            >
              {works.map((project, key) => {
                return (
                  <li
                    onClick={() => handlePreview({ ...project.attributes })}
                    key={key}
                    className="c-projectsList__gallery-item"
                  >
                    <Project {...project.attributes} />
                  </li>
                );
              })}
            </Masonry>
            {gridLoaded && currentPage + 1 <= totalPages && (
              <div className="c-projectsList-btn">
                <button
                  onClick={() => loadMore()}
                  className="custom-btn btn-border"
                >
                  <span>Voir plus</span>
                </button>
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
      <PopinProject
        onClose={() => setOpenPopin(false)}
        isOpen={openPopin}
        {...infosPopin}
      />
    </>
  );
}

export default ProjectsList;
