export const formatDate = (timestamp) => {
    if (timestamp) {
        const date = new Date(timestamp);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        return (
            `${day}/${month}/${year}`
        )
    }
}

export const formatHour = (timestamp) => {
    if (timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) {
            hours = '0' + hours
        }
        if (minutes < 10) {
            minutes = '0' + minutes
        }
        return (
            `${hours}h${minutes}`
        )
    }
}