import React, { useEffect, useState } from "react";
import TitleSticky from "../Components/TitleSticky";
import ContactForm from "../Components/ContactForm";

// LIB
import ReactMarkdown from "react-markdown";

const getContent = async () => {
  return await fetch(`https://clapaint.fly.dev/api/contents`).then(res => {
    return res.json();
  });
};

function Contact() {
  const [content, setContent] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const description = await getContent();
      setContent(description.data[0]);
      setIsLoaded(true);
    })();
  }, []);

  return (
    <div className="t-contact">
      <TitleSticky title={"<strong>Contactez</strong>-moi"} />

      <div className="t-contact__container">
        {isLoaded && content.attributes.content && (
          <div className="t-contact__container-infos">
            <ReactMarkdown>{content.attributes.content}</ReactMarkdown>
          </div>
        )}
        <div className="t-contact__container-form">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
