import React, { useEffect, useState } from "react";
// COMPONENTS
import Event from "./Event";
// LIB
import qs from "qs";

const getEvents = async () => {
  const query = qs.stringify(
    {
      populate: ["picture"],
      filters: {
        is_highlight: true,
        date_end: { $gte: new Date() },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return await fetch(`https://clapaint.fly.dev/api/events?${query}`).then(
    res => {
      return res.json();
    }
  );
};

function MainEvent() {
  const [mainEvents, setMainEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const newPrevEvents = await getEvents();
      setMainEvents(newPrevEvents.data);
      setEventsLoaded(true);
    })();
  }, []);

  return (
    <>
      {eventsLoaded && (
        <>
          {mainEvents.length > 0 && (
            <div className="c-mainEvent">
              {mainEvents.map((event, key) => {
                return <Event key={key} {...event.attributes} />;
              })}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MainEvent;
