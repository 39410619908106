import React, { useRef } from 'react';

function PopinProject({ onClose, isOpen, title, width, height, picture, is_available }) {
    const wrapperPopinRef = useRef(null);

    const handleClickOutside = (event) => {
        if (wrapperPopinRef.current && !wrapperPopinRef.current.contains(event.target)) {
            onClose();
        }
    }

    return (
        <div onClick={(e) => handleClickOutside(e)} className={`c-popinProject ${isOpen ? 'open' : ''}`}>
            <button onClick={onClose} className="c-popinProject__close-btn"></button>
            <div ref={wrapperPopinRef} className="c-popinProject__container">
                <h2 className="c-popinProject__container-title">{title}</h2>
                {
                    isOpen &&
                    <div className="c-popinProject__visuel" style={{ backgroundImage: `url(${picture.data.attributes.url})` }}></div>
                }
                {
                    isOpen &&
                    <>
                        {
                            width && height &&
                            <p className="c-popinProject__size">Dimensions: <strong>{width} x {height}</strong></p>
                        }
                        <p className="c-popinProject__available">Disponible: <strong>{is_available ? "Oui" : "Non"}</strong></p>
                    </>
                }
            </div>
        </div>
    )
}

export default PopinProject;
