
function Loader() {

    return (
        <div className="c-loader">
            <div></div><div></div>
            <p className="c-loader-text">Chargement...</p>
        </div>
    );
}

export default Loader;
