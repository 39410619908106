import { Routes, Route, useLocation } from "react-router-dom";


import React, { useEffect, useState } from 'react';
import './Styles/App.scss';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Homepage from './Templates/Homepage';
import Evenements from './Templates/Evenements';
import Contact from './Templates/Contact';


function App() {

  let location = useLocation();
  const [animation, setAnimation] = useState('next');

  useEffect(() => {
    if (location.pathname === '/') {
      setAnimation('next')
    } else if (location.pathname === '/contact') {
      setAnimation('prev')
    } else {
      return
    }
  }, [location])

  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/evenements" element={<Evenements animationName={animation} />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
