import React, { useState, useEffect } from "react";
// COMPONENTS
import Event from "./Event";
import Loader from "./Loader";
// IMG
import Emoji from "../Assets/img/emoji.png";
// LIB
import qs from "qs";

const getEvents = async (type, pageNumber) => {
  const params =
    type === "PREV"
      ? {
          $lt: new Date(),
        }
      : {
          $gte: new Date(),
        };
  const query = qs.stringify(
    {
      sort: ["date_start:desc"],
      populate: ["picture"],
      pagination: {
        page: pageNumber,
        pageSize: 10,
      },
      filters: {
        date_end: params,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return await fetch(`https://clapaint.fly.dev/api/events?${query}`).then(
    res => {
      return res.json();
    }
  );
};

function EventsList() {
  const filterBtns = ["À venir", "Passés"];
  const [nextEvents, setNextEvents] = useState([]);
  const [prevEvents, setPrevEvents] = useState([]);
  const [pageNextEvents, setPageNextEvents] = useState(1);
  const [pagePrevEvents, setPagePrevEvents] = useState(1);
  const [totalPagePrevEvents, setTotalPagePrevEvents] = useState();
  const [totalPageNextEvents, setTotalPageNextEvents] = useState();
  const [eventsLoaded, setEventsLoaded] = useState(false);

  const [activeFilter, setActiveFilter] = useState(0);
  const handleClickFilter = index => {
    setActiveFilter(index);
  };

  const loadMore = async (type, pageNumber) => {
    const newDatas = await getEvents(type, pageNumber + 1);
    if (type === "PREV") {
      setPrevEvents([...prevEvents, ...newDatas.data]);
      setPagePrevEvents(pageNumber + 1);
    } else if (type === "NEXT") {
      setNextEvents([...nextEvents, ...newDatas.data]);
      setPageNextEvents(pageNumber + 1);
    } else {
      return;
    }
  };

  useEffect(() => {
    (async () => {
      const newPrevEvents = await getEvents("PREV", pagePrevEvents);
      setPrevEvents(newPrevEvents.data);
      setTotalPagePrevEvents(newPrevEvents.meta.pagination.pageCount);

      const newNextEvents = await getEvents("NEXT", pageNextEvents);
      setNextEvents(newNextEvents.data);
      setTotalPageNextEvents(newNextEvents.meta.pagination.pageCount);

      setEventsLoaded(true);
    })();
  }, []);

  return (
    <div className="c-eventsList">
      <div className="c-eventsList__filters">
        {filterBtns &&
          filterBtns.map((btn, index) => {
            return (
              <button
                key={index}
                onClick={() => handleClickFilter(index)}
                className={`c-eventsList__filters-item ${
                  activeFilter === index ? "active" : ""
                }`}
              >
                {btn}
              </button>
            );
          })}
      </div>
      {eventsLoaded ? (
        <>
          {activeFilter === 0 ? (
            nextEvents.length > 0 ? (
              <>
                <ul className="c-eventsList__container">
                  {nextEvents.map((event, key) => {
                    return (
                      <li key={key} className="c-eventsList__item">
                        <Event {...event.attributes} />
                      </li>
                    );
                  })}
                </ul>
                {pageNextEvents + 1 <= totalPageNextEvents && (
                  <div className="c-eventsList-btn">
                    <button
                      onClick={() => loadMore("NEXT", pageNextEvents)}
                      className="custom-btn btn-border"
                    >
                      <span>Voir plus</span>
                    </button>
                  </div>
                )}
              </>
            ) : (
              <h3 className="c-eventsList-title">
                Pas encore d'évènement programmé <img src={Emoji} alt="" />
              </h3>
            )
          ) : prevEvents.length > 0 ? (
            <>
              <ul className="c-eventsList__container">
                {prevEvents.map((event, key) => {
                  return (
                    <li key={key} className="c-eventsList__item">
                      <Event {...event.attributes} />
                    </li>
                  );
                })}
              </ul>
              {pagePrevEvents + 1 <= totalPagePrevEvents && (
                <div className="c-eventsList-btn">
                  <button
                    onClick={() => loadMore("PREV", pagePrevEvents)}
                    className="custom-btn btn-border"
                  >
                    <span>Voir plus</span>
                  </button>
                </div>
              )}
            </>
          ) : (
            <h3 className="c-eventsList-title">
              Pas d'évènement pour le moment
            </h3>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default EventsList;
