import React from "react";
import Logo from "../Assets/img/logo.png"

function Footer() {

    return (
        <div className="c-footer">
            <ul className="c-footer__container">
                <li className="c-footer__container-item footer-infos">
                    <p>Artiste Peintre</p>
                    <p>Clarisse de Vaublanc</p>
                </li>
                <li className="c-footer__container-item">
                    <img className="c-footer__container-item-logo" src={Logo} alt="logo" />
                </li>
            </ul>
        </div>
    );
}

export default Footer;
