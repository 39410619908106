import React, { useRef, useEffect, useState } from 'react'

function TitleSticky({ title }) {

    const [isTop, setIsTop] = useState(false);
    const titleEl = useRef(null);
    useEffect(() => {
        const stickyTitle = () => {
            if (titleEl.current && titleEl.current.offsetTop > (window.pageYOffset + 50)) {
                setIsTop(false)
            } else {
                setIsTop(true)
            }
        };
        window.addEventListener("scroll", stickyTitle);
        window.addEventListener('resize', stickyTitle);

        return () => {
            window.removeEventListener("scroll", stickyTitle);
            window.removeEventListener('resize', stickyTitle);
        }
    }, []);

    return (

        <h1 ref={titleEl} className={`c-title-sticky  ${isTop ? 'top' : ''}`} dangerouslySetInnerHTML={{ __html: title }}></h1>

    )
}

export default TitleSticky;
