import TitleSticky from '../Components/TitleSticky';
import EventsList from '../Components/EventsList';
import MainEvent from '../Components/MainEvent';


function Evenements({ animationName }) {
    return (
        <div className={`t-evenements ${animationName}`}>
            <TitleSticky title={'Mes <strong>Evenements</strong>'} />
            <MainEvent />
            <EventsList />
        </div>

    );
}

export default Evenements;
